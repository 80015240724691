import React from "react";
import { Layout } from "../components/Layout";
import { Link } from "../components/Link";
import { Box } from "@xstyled/styled-components";

export default function About() {
  return (
    <Layout title="About">
      <Box mt={150}>
        This website's pattern was originally created by Théo Mesnil, you can
        find the code on his{" "}
        <Link
          target="_blank"
          href="https://github.com/theo-mesnil/theomesnil.com"
        >
          github
        </Link>
        .
      </Box>
      <Box mt="xxl">
        Made with ReactJs, Gatsby and{" "}
        <Link href="https://xstyled.dev" target="_blank">
          xstyled
        </Link>
        .
      </Box>
      <Box mt="xs">Hosted by Vercel.</Box>
    </Layout>
  );
}
